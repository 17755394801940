/*  coupon scan css */
.coupon-scan-container {
    padding: 4em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.coupon-img-container {
    width: 200px;
    height: 200px;
}

.merchant-scan-text {
    color: #8F9CB3 !important;
}

.status-text {
    padding: 1em;
    width: fit-content;
    border-radius: 100px;
    border: 1px solid rgb(202, 202, 202);
    opacity: 0.6;
}

.used-status-text {
    background-color: grey;
}

.unused-status-text {
    background-color: aquamarine;
}