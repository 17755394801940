.container,
.sign-up-container {
    flex: 1;
    background-color: rgba(var(--color-fff), 0.5);
    border-radius: 12px;
    max-width: 40vh;
}

.auth-form-container{
    width: 90%;
}
.deleteIcon {
    position: absolute;
    color: white;
    top: 40px;
    /* z-index: 5; */
    right: 55px;
    background-color: black;
    padding: 2px;
    height: 25px;
    width: 25px;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0px 2px 4px black;
}

.carouselImageContainer {
    width: 80vw;
    height: 65vw;
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: hidden;
}

.carouselImage {
    width: 100%;
    height: 100%;
}

.paginationDots {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-left: 8px;
    margin-right: 8px;
}

.slideUploadContainer {
    position: relative;
}

.card-body .form-control,
.card-body .form-select {
    background-color: var(--dark-purple)!important;
    background:
    linear-gradient(var(--dark-purple) 0 0) padding-box,
    linear-gradient(25deg, rgb(210, 46, 46) 0%, rgb(237, 115, 39) 100%) border-box;
    padding: 10px;
    border: 2px solid transparent;
    border-radius: 8px;
    display: inline-block;
}

.card-body .form-control::before,
.card-body .form-select::before {
    z-index: -1;
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 12px;
    background: linear-gradient(87.36deg, #FF731D 6.42%, #0DD7E3 84.24%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
                mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
    pointer-events: none;
}

.otp-card-body .form-control,
.otp-card-body .form-select {
    background:
    linear-gradient(#121115 0 0) padding-box,
    linear-gradient(25deg, rgb(210, 46, 46) 0%, rgb(237, 115, 39) 100%) border-box!important;
    height: 4em;
}

.addCouponImageContainer {
    align-items: center;
    justify-content: center;
    border-color: gray;
    border-width: 1px;
    margin-top: 30px;
    width: 80px;
    height: 270px;
}

#storeImageInput{
    display: none;
  }

.couponCarouselImage {
    height: 100%;
    width: 100%;
}

.couponCarouselImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
}

#delete_btn{
    background-color: red;
}

.orange-red-gradient-text{
    background: var(--light-orange-gradient);
    -webkit-text-fill-color: transparent; 
            -webkit-background-clip: text; 
}

.links-border {
    z-index: 1;
    border-radius: 12px;
    padding: 10px 3rem;
    display: inline-block;
    position: relative;
    background: rgba(0, 0, 0, 0.7);
    border: none;
}
.links-border::after {
    z-index: -1;
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 12px;
    background: linear-gradient(25deg, rgb(210, 46, 46) 0%, rgb(237, 115, 39) 100%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
                mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
    pointer-events: none;
}