.following-container {
    background-color: rgb(243, 242, 241);
    padding: 0.7em 0.8em;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 6px;
}

.following-container:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cursor-pointer {
    cursor: pointer;
}

.following-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50% !important;
}