.coupons_list_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

.addCouponContainer {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-bottom: 6px;
}

.addCouponContainer > span {
    cursor: pointer;
}

.coupon_image_container {
    width: 220px;
    height: 150px;
    background: #2f2f2f; 
    overflow: hidden;
}

.coupon_image_container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.9;
    &:hover {
        opacity: 1;
    }
    
}