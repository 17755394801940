.container-sign-in-up {
    flex: 1;
}

#country-code-select {
    width: 40%;
    margin: 0;
}

#phone-container {
    display: flex;
    flex-direction: row;
}

.design-image{
    background-color: black;
    width: 100%;
    height: 100%;
}

.sign-in-half{
    align-content: center;
    background-color: black;
}

.sign-container{
    background-color: #D1D4FF1A;
    border-radius: 16px;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
}

.detail-sign-in{
    color: #7D7D7D;
    font-size: small;
}

.label-text{
    color: #DBDBDB;
    font-weight: 500;
    font-size: small;
}

#sign-in-up-input{
    background-color: #FFFFFF0F;
    border-radius: 10px;
    font-size: small;
    padding: 10px;
}

#sign-in-up-input::placeholder {
    color: #8C8C8C;
    font-weight: 400;
}

#sign-in-or-divider{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    color: #9A9A9A;
}

#sign-in-or-divider hr {
    flex: 1;
    border: none;
    border-top: 1px solid #9A9A9A;
}

#sign-in-google-btn{
    all: unset;
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 10px;
    padding: 1px;
}

#sign-in-google-btn-content{
    display: block;
    background-color: #272727;
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-size: small;
    text-align: center;
}

.bottom-sign-in-up-container{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: center;
    margin-top: 10px;
}

.bottom-sign-in-up-label{
    color: #667177;
    font-weight: 400;
    font-size: small;
}

.sign-in-up-link{
    color: #D2DDE5;
    font-weight: 400;
    font-size: small;
}

#forgot-password-link{
    color: #D2DDE5;
    font-weight: 400;
    font-size: small;
    text-align: center;
    margin-top: 10px;
}

.logo-image{
    width: 3rem;
}

#password-toggle-icon{
    cursor: pointer;
    color: #A6A6A6;
}

#sign-in-or-divider-text{
    font-size: small;
}

#register-with-email-detail{
    font-size: small;
    text-align: center;
    margin-top: 10px;
}

.error-message{
    color: red;
    font-size: small;
}

@media (max-width: 480px) {
    .bottom-sign-in-up-container{
        align-items: center;
        flex-direction: column;
        gap: 5px;
    }
}

@media (max-width: 992px) {
    .container-sign-in-up {
        width: 100dvw;
        height: 100dvh;
        padding: 2rem 0 2rem 0;
    }

    .detail-sign-in{
        font-size: medium;
    }

    .sign-in-half {
        align-self: center;
        padding: 2rem;
    }

    .design-image{
        display: none;
    }

    .label-text{
        font-size: medium;
    }

    #sign-in-up-input{
        font-size: medium;
    }

    #sign-in-or-divider-text{
        font-size: medium;
    }

    #sign-in-google-btn-content{
        font-size: medium;
    }

    #forgot-password-link{
        font-size: medium;
    }

    .bottom-sign-in-up-label{
        font-size: medium;
    }

    .sign-in-up-link{
        font-size: medium;
    }

    .sign-container{
        width: 80%;
    }
}
