/*
* Admin Layout (cryptoon)
* @author: Pixelwibes
* @design by: Pixelwibes.
* @event-namespace:cryptoon
* Copyright 2022 Pixelwibes
*/

// bootstrap class overwrite css
@import "@fontsource/poppins";

.border,
.border-top,
.border-right,
.border-bottom,
.border-left,
.dropdown-divider {
    border-color: var(--border-color);
}

.border {
    &.border-primary{
        border-color: var(--primary-color) !important;
    }
    &.border-secondary{
        border-color: var(--secondary-color) !important;
    }
    &.border-success{
        border-color: $color-success !important;
    }
    &.border-danger{
        border-color: $color-danger !important;
    }
    &.border-warning{
        border-color: $color-warning !important;
    }
    &.border-info{
        border-color: $color-info !important;
    }
    &.border-dark{
        border-color: var(--color-900) !important;
    }
    &.border-white{
        border-color: var(--color-fff) !important;
    }
}

.container-xxl{
    width: 100%!important;
}
.font-weight-bold{
    font-weight: bold;
}
.table>:not(caption)>*>*{
    padding: .8rem .8rem;
}
.zindex-fixed{
    z-index: $zindex-fixed;
}
.zindex-popover{
    z-index: $zindex-popover;
}
.zindex-modal{
    z-index: $zindex-modal;
}
.small-xs{
    font-size: 13px !important;
}

// .form-control
.bootstrap-tagsinput{
    width: 100%;
}
.form-label{
    font-weight: 600;
}
    
.required:after {
    content:" *";
    color: $color-danger;
    font-size: 10pt;
  }

.form-control,.form-select {
    border-color: transparent;
    background-color: rgba($dark, .2);
    color: var(--white-color);
    font-size: 16px;
    &::placeholder{
        color: var(--white-color);
    }
    &:focus{
        border-color: transparent;
        background-color: rgba($dark, .6);
        box-shadow: 0 0 10px rgba($dark, .15);
        color: var(--white-color);
    }

    &.form-control-lg{
        min-height: calc(1.5em + 1.8rem + 2px);
    }
}

.create-character-form .form-control,
.create-character-form .form-select {
    background-color: var(--dark-purple)!important;
    background:
    linear-gradient(var(--dark-purple) 0 0) padding-box,
    linear-gradient(25deg, rgb(210, 46, 46) 0%, rgb(237, 115, 39) 100%) border-box;
    padding: 10px;
    border: 2px solid transparent;
    border-radius: 8px;
    display: inline-block;
}

.create-character-form .form-control::before,
.create-character-form .form-select::before {
    z-index: -1;
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 12px;
    background: linear-gradient(87.36deg, #FF731D 6.42%, #0DD7E3 84.24%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
                mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
    pointer-events: none;
}

.create-schedule-form .form-control,
.create-schedule-form .form-select {
    background-color: var(--dark-gray)!important;
    background:
    linear-gradient(var(--dark-gray) 0 0) padding-box,
    linear-gradient(25deg, rgb(210, 46, 46) 0%, rgb(237, 115, 39) 100%) border-box;
    padding: 10px;
    font-size: 15px;
    border: 2px solid transparent;
    border-radius: 15px;
    display: inline-block;
}

.input-group-text{
    border-color: var(--border-color);
    background-color: var(--color-200);
    color: var(--text-color);
}

.form-control-lg {
    @media only screen and (max-width: $break-small) {
        min-height: calc(1.5em + 1rem + 2px) !important;
    }
}

.badge-info{
    background: var(--primary-color);
}

// form Switches css
.form-switch {
    .form-check-input{
        &:checked {
            background-color: var(--tertiary-color);
            border-color: var(--border-color);
        }
    }
    
}

.scheduled-message-btn{
    background-color: var(--tertiary-color);
}

.btn-link{
    color: var(--text-color);
    &:hover{
        color: var(--secondary-color);
    }
}
.bg-white{
    color: var(--text-color);
}
//lineheight
.fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
    line-height: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
    line-height: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
    line-height: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
    line-height:calc(1.275rem + 0.3vw) !important;
}
// bottom
.btn {
    font-size: $font-size;
    color: var(--text-color);
    border-color: transparent;
    &:hover{
        color: var(--text-color);
        border-color: transparent;
    }
    &:disabled {
        border-color: transparent;
    }
    &.btn-primary {
        background-color: var(--primary-color);
        color: var(--white-color);
        &.active {
            background-color: var(--primary-color);
            border-color: transparent;
        }
        &:hover{
            background-color: var(--secondary-color);
            border-color: transparent;
        }
    }

    &.btn-light-primary {
        background-color: rgba(var(--primary-rgb), 0.15);
        color: var(--primary-color);
        border-color: transparent;

        &:hover{
            background-color: var(--primary-color);
            color: $white;
        }
    }

    &.btn-light-secondary{
        background-color: rgba($color-secondary, .15);
        color: $color-secondary;
        border-color: transparent;

        &:hover{
            background-color: $color-secondary;
            color: $white;
        }
    }

    &.btn-light-success{
        background-color: rgba($color-success, .15);
        color: $color-success;
        border-color: transparent;

        &:hover{
            background-color: $color-success;
            color: $white;
        }
    }

    &.btn-light-danger{
        background-color: rgba($color-danger, .15);
        color: $color-danger;
        border-color: transparent;

        &:hover{
            background-color: $color-danger;
            color: $white;
        }
    }

    &.btn-light-warning{
        background-color: rgba($color-warning, .15);
        color: $color-warning;
        border-color: transparent;

        &:hover{
            background-color: $color-warning;
            color: $white;
        }
    }

    &.btn-light-info{
        background-color: rgba($color-info, .15);
        color: $color-info;
        border-color: transparent;

        &:hover{
            background-color: $color-info;
            color: $white;
        }
    }

    &.btn-outline-primary {
        color: var(--primary-color);
        border-color: var(--primary-color);
        &:hover{
            color: $white;
            background-color: var(--primary-color);
        }
        &.active{
            background-color: var(--primary-color);
            color: var(--white-color);
        }
    }    
    &.btn-outline-secondary {
        border-color: var(--border-color);
        &:hover{
            color: $white;
            background-color: var(--secondary-color);
            i{
                color: $white !important;
            }
        }
    }
    &.btn-outline-success{
        color: $color-success;
        border-color: $color-success;
        &:hover{
            background-color: $color-success;
            color: $white;
        }
    }
    &.btn-outline-danger{
        color: $color-danger;
        border-color: $color-danger;
        &:hover{
            background-color: $color-danger;
            color: $white;
        }
    }
    &.btn-outline-info{
        color: $color-info;
        border-color: $color-info;
        &:hover{
            background-color: $color-info;
            color: $white;
        }
    }
    &.btn-dark {
        color: var(--white-color);
    }
    &.btn-secondary{
        color: var(--white-color);
    }
    &.btn-lg {
        padding: .7rem 1rem;
    }
}
.btn-close{
    color: var(--text-color);
}
// avatar
.avatar {
    width: 40px;
    min-width: 40px;
    height: 40px;
    
    &.xs{
        width: 16px;
        min-width: 16px;
        height: 16px;
    }
    &.sm{
        width: 22px !important;
        min-width: 22px !important;
        height: 22px !important;
        i{
            font-size: 12px;
            line-height: 12px;
            vertical-align: text-top
        }
    }
    &.lg{
        width: 56px;
        min-width: 56px;
        height: 56px;
    }
    &.xl{
        width: 80px;
        min-width: 80px;
        height: 80px;
    }
    &.xxl{
        width: 120px;
        min-width: 120px;
        height: 120px;
    }
    &.no-thumbnail{  
        background-color: rgba(var(--primary-rgb), 0.15);
        color: var(--primary-color);
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.min-width-55{
    min-width: 55px;
}
// dropdown menu
.dropdown-item:hover,
.dropdown-item:focus {
    background-color: rgba($dark, .1);
}
.dropdown-item{
    color: var(--text-color);
    &:hover{
        color: var(--secondary-color);
    }
}
.dropdown-menu {
    font-size: $font-size + 1px;
    color: var(--text-color);
    background-color: #B4B4B4;
    li{
        margin:5px 0px;
    }
    .dropdown-item{
        color: rgba(39, 39, 39, 1);
        &.active{
            color: var(--secondary-color);
            background-color: var(--primary-color);
        }
        &:active,&:focus{
            background-color: var(--primary-color);
            color: var(--secondary-color);
        }
        &:hover{
            color: var(--text-color);
            background-color: rgba(39, 39, 39, 1);
        }
    }
}

// custom modal popup css
.modal {
    right: 0;
    left: auto;
    z-index: 99999;
    
    &.fade{
        .modal-dialog-vertical{
            transform: translateX(-100%);
        }
    }
    &.show{
        .modal-dialog-vertical{
            transform: translateX(0);
        }
    }
    .modal-dialog-vertical{
        margin: 0;
        height: 100%;
        .modal-content{
            height: 100%;
        }
    }
    .modal-header,.modal-footer{
        border-color: var(--border-color);
    }
    .modal-content{
        background-color: var(--body-color);
    }
}

.modal{
    &.right{
        z-index: 99999;
        margin-top: 25px;
        margin-bottom: 25px;
        @media only screen and (max-width: 1279px) {          
            margin-top: 0px;
            margin-bottom: 0px;    
        } 
        .modal-dialog{
            height: calc(100vh - 50px) !important;
            position: fixed;
            margin: auto;
            width: 320px;
            height: 100%;
            -webkit-transform: translate3d(0%, 0, 0);
            -ms-transform: translate3d(0%, 0, 0);
            -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
            @media only screen and (max-width: 1279px) {
                height: 100vh !important;
            }
            @media only screen and (max-width: 767px) {
                height: 100vh !important;
            }
        }
        .modal-content {
            height: 100%;
            border-radius: 1.1rem !important;
            @media only screen and (max-width: 1279px) {
                border-radius: 0px !important;
            }
            .setting-theme,.dynamic-block,.setting-font,.sidebar-gradient{
                border-bottom: 1px solid #e2e2e2;
            }
            .setting-font,.setting-mode,.dynamic-block{
                .list-group .list-group-item {
                    background-color: transparent;
                    border-color: transparent;
                    padding: 0px !important;
                    margin-bottom: 5px;
                }
            }
            .font_setting{
                .form-check-input:checked{
                    background-color: var(--secondary-color);
                    border-color: var(--secondary-color);
                    background-image: inherit;
                }
            }
            .setting-mode{
                .form-check-input:checked{
                    background-color: var(--secondary-color);
                    border-color: var(--secondary-color);
                }
            }
            .dynamic-block{
                .list-group-item{
                    .avatar{
                        &.xs{
                            width: 25px;
                            height: 25px;
                            min-width: 25px;
                            border-radius: 5px !important;
                        }
                    }
                }
            }
            .modal-footer{
                @media only screen and (max-width: 767px) {
                    display: none;
                }
            }
        }
        .modal-body{
            @include overflow(scroll);
            overflow-y: auto;
        }
        &.fade{
            .modal-dialog{
                right: -320px;
                -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
                -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
                -o-transition: opacity 0.3s linear, right 0.3s ease-out;
                transition: opacity 0.3s linear, right 0.3s ease-out;
            }
            &.show{
                .modal-dialog{
                    right: 10px;
                    @media only screen and (max-width: 1279px) {
                        right: 0px;
                    }
                }
            }
        }
    }
}

// breadcrumb
.breadcrumb {
    background-color: var(--color-200);
}

// navbar  
.navbar-light {
    .navbar-nav {
        .nav-link {
            color: var(--color-500);
            &:hover,
            &:focus{
                color: var(--primary-color);
            }
        }
    }
}

// custom tab ui
.nav-tabs {
    border-color: var(--border-color);
    &.tab-custom{
        border: 1px solid rgba($white, .2);
        .nav-link{
            color: $white;
            opacity: 0.7;
            &.active{
                opacity: 1;
                color: var(--primary-color);
            }
        }
    }
    &.tab-card{
        .nav-link{
            border-top: 0;
            border-left: 0;
            border-right: 0;
            background-color: transparent;
            border-bottom: 3px solid transparent;
            color: var(--color-500);

            &.active{
                border-color: var(--primary-color);
            }
        }
    }
    &.tab-body-header{
        border: 1px solid Var(--primary-color);
        overflow: hidden;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
        .nav-item{
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
            .nav-link{
                border: 0;
                margin: 4px;
                border-radius: 0;
                color: var(--color-500);

                &:hover{
                    color: var(--primary-color);
                }

                &.active{
                    background-color: var(--primary-color);
                    color: $white;
                }

                &.buy{
                    &.active{
                        background-color: var(--buy-color);
                        color: $white;
                    }
                }

                &.sell{
                    &.active{
                        background-color: var(--sell-color);
                        color: $white;
                    }
                }
            }
        }

    }
}

// nav pill
.nav-pills {
    .nav-link.active,
    .show>.nav-link{
        background: #272727;
        border: 1.5px solid #FF731D !important;
        // background: linear-gradient(266deg, rgba(249,139,38,1) 0%, rgba(250,33,33,1) 100%);
        &:hover{
            color: var(--color-fff);
        }
    }
    .nav-link{
        background: rgba(209, 212, 255, 0.1);
        color: var(--text-color);
        &:hover{
            color: var(--text-color);
        }
    }
}

.nav-pills{
    border-radius: 12px;
    padding: 5px;
    background-color: rgba($color: #D1D4FF1A, $alpha: 0.1);
}

//border
.border-dark{
    border-color: var(--text-color) !important;
}
.border-start{
    border-color: var(--border-color) !important;
}

// list-group design
.list-group-custom,.list-group {
    .list-group-item {
        /* z-index: 1; */
        border-radius: 12px;
        padding: 10px 3rem;
        display: inline-block;
        position: relative;
        background: rgba(209, 212, 255, 0.1);
        // background: linear-gradient(0deg, rgb(30, 34, 64) 0%, rgb(46, 49, 76) 50%, rgb(73, 78, 116) 100%);
        border: none;
    }
    .list-group-item::before {
        z-index: -1;
        content: "";
        position: absolute;
        inset: 0;
        padding: 2px;
        border-radius: 12px;
        background: rgba(209, 212, 255, 0.1);
        // background: linear-gradient(0deg, rgba(62,66,106,1) 0%, rgb(115, 119, 165) 100%),
        // linear-gradient(0deg, #FFFFFF, #FFFFFF);
        //     -webkit-mask: 
        //     linear-gradient(#fff 0 0) content-box,
        //     linear-gradient(#fff 0 0);
        //             mask: 
        //     linear-gradient(#fff 0 0) content-box,
        //     linear-gradient(#fff 0 0);
        //     -webkit-mask-composite: xor;
        //             mask-composite: exclude;
        pointer-events: none;
    }
}

.list-group-item-selected {
    /* z-index: 1; */
    border-radius: 12px;
    padding: 10px 3rem;
    display: inline-block;
    position: relative;
    background: #272727;
    border: none;
}
.list-group-item-selected::before {
    /* z-index: -1; */
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 12px;
    background: rgba(209, 212, 255, 0.1);
    // linear-gradient(0deg, #FFFFFF, #FFFFFF);
    //     -webkit-mask: 
    //     linear-gradient(#fff 0 0) content-box, 
    //     linear-gradient(#fff 0 0);
    //             mask: 
    //     linear-gradient(#fff 0 0) content-box, 
    //     linear-gradient(#fff 0 0);
    //     -webkit-mask-composite: xor;
    //             mask-composite: exclude;
    pointer-events: none;
}

// .progress bar design
.progress {
    background-color: var(--color-100);
    &.plan-active{
        background-color: var(--color-300);
    }
}

// table 
.table {
    border-color: var(--border-color);
    tr{
        th{
            //border-color: var(--primary-color);
            color: var(--color-800);
            text-transform: uppercase;
            font-size: 12px;
        }
        td{
            border-color: var(--border-color);
            color: var(--color-500);
            padding: .8rem .6rem;
        }
        &:hover{
            td{
                color: var(--color-900);
            }
        }
    }
    &.border-primary{
        tr th,
        tr td{
            border-color: var(--primary-color);
        }
    }
    &.table-sm{
        td{
            padding: .5rem .6rem;
        }
    }
    &.custom-table{
        border-collapse: separate !important;
        border-spacing: 0 5px !important;

        thead{
            tr{
                th{
                    border: 0;
                }
            }
        }
        tbody{
            tr{
                background: var(--card-color);
                &.cancle-row{
                     td{
                        //background: rgba($color-danger, .05);
                     }
                }
            }    
        }
        tr{
            td, th{
                vertical-align: middle;
                white-space: nowrap;
                padding-left: 1rem;
                padding-right: 1rem;
                border-right: 0;
                border-left: 1px solid var(--border-color);
                
                &:first-child{
                    border-radius: 0.25rem 0 0 0.25rem;
                    border-left: 0;
                }
                &:last-child{
                    border-radius: 0 0.25rem 0.25rem 0;
                }
            }
        }
    }
    &.custom-table-2{
        border-collapse: separate !important;
        border-spacing: 0 1px !important;

        thead{
            tr{
                th{
                    border: 0;
                }
            }
        }
        tbody{
            tr{
                background: var(--card-color);
                &.cancle-row{
                     td{
                        background: rgba($color-danger, .05);
                    }
                }
                &.active-row{
                     td{
                        background: rgba($color-info, .05);
                    }
                }
            }    
        }
        tr{
            td, th{
                vertical-align: middle;
                white-space: nowrap;
                padding-left: 1rem;
                padding-right: 1rem;
                border-right: 0;
                
                &:first-child{
                    border-radius: 0.25rem 0 0 0.25rem;
                    border-left: 0;
                }
                &:last-child{
                    border-radius: 0 0.25rem 0.25rem 0;
                }
            }
        }
    }
    &.accordion{
        border-collapse: separate !important;
        border-spacing: 0 1px !important;

        thead{
            tr{
                th{
                    border: 0;
                }
            }
        }
        tbody{
            tr{
                background: var(--card-color);
                &.cancle-row{
                     td{
                        background: rgba($color-danger, .05);
                    }
                }
                &.active-row{
                     td{
                        background: rgba($color-info, .05);
                    }
                }
            }    
        }
        tr{
            td, th{
                padding-left: 1rem;
                padding-right: 1rem;
                border-right: 0;
                
                &:first-child{
                    border-radius: 0.25rem 0 0 0.25rem;
                    border-left: 0;
                }
                &:last-child{
                    border-radius: 0 0.25rem 0.25rem 0;
                }
            }
        }
    }
}

// pagination
.page-item{
    &.disabled{
        .page-link{
            background-color: var(--border-color);
            border-color: var(--border-color);
        }
    }
    .page-link{
        background-color: var(--card-color);
        border-color: var(--border-color);
        color:var(--primary-color);
    }
    &.active{
        .page-link{
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            color:var(--white-color);
        }
    }
}

//Side navbar
.side-navbar{
    li{
        margin-bottom: 5px;
        a{
            color: var(--text-color);
        }
    }
}

//button
.btn-outline-secondary.btn-block{
    color: #fff !important;
}

//navbar
.navbar-toggler:focus{
    box-shadow: none;
}

//Date & Time 
input[type="date"],input[type="time"]
{
    display:block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-width:95%;
}

//tageinput
.bootstrap-tagsinput,.dropify-wrapper{
    border: 1px solid var(--border-color);
    background-color: var(--card-color);
}

.poppins-font{
    font-family: "Poppins";
}

.new-container-black{
    background-color: black
}
