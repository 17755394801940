.message-sender-name {
    color: gray;
}

.character-price {
    background-color: transparent;
}
.character-price .form-control, .character-price .form-select{
    background: 
    linear-gradient(var(--dark-purple) 0 0) padding-box,
    linear-gradient(25deg, rgba(255, 119, 119, 1) 0%, rgba(255, 115, 29, 1) 100%) border-box;
    padding: 10px;
    border: 2px solid transparent;
    border-radius: 15px;
    display: inline-block;
}

.public-chat-item {
    cursor: pointer;
    width: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.public-chat-item:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ai-character-avatar {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.ai-character-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #ff731d;
}

.ai-character-avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #ff731d;
}

.ai-character-card-container {
    margin-top: 2em;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    box-shadow: 20px 8px 80px 0px #0000000a;
    /* background-color: #ffffff; */
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    width: fit-content;
    gap: 2em;
    width: 340px;
    cursor: pointer;
    padding: 1em;
}

.ai-character-info {
    text-align: center;
}

.ai-character-card-p {
    max-height: 30vh;
    overflow: hidden;
}

.ai-profiles-tab {
    width: 50%;
}

.public-chat-avatar {
    border: 1.2px solid #ff731d !important;
}

.public-message-date {
    font-size: x-small;
}

.message-reaction-container {
    display: flex;
    flex-direction: row;
    gap: 0.3em;
    align-items: baseline;
    font-size: small;
    background-color: white;
    padding: 0.3em 0.4em;
    border-radius: 13px;
    color: black;
    cursor: pointer;
}

.opacity-50 {
    opacity: 0.5;
}

.message-reaction-icon {
    width: 13px;
    height: 13px;
}

.message-reaction-icon img {
    width: 100%;
    height: 100%;
}

.received-message-container {
    background: linear-gradient(333deg, rgba(35,112,202,1) 0%, rgba(68,0,155,1) 100%);
    border-radius: 0px 24px 24px 24px;
}

.sent-message-container {
    background: linear-gradient(333deg, rgb(38, 52, 69) 0%, rgba(55,61,75,.96) 100%);
    border-radius: 24px 24px 0px 24px;
}

.text-white {
    color: white;
}

.public-message-comments {
    background: #ff731d;
    box-shadow: 0px 4px 12px 0px #0000003d;
    border-radius: 24px;
    padding: 1em;
    height: 90%;
}

.public-message-comments > p {
    color: white;
}

.public-message-comments .comment {
    padding: 8px;
    border-radius: 12px;
    gap: 8px;
    background: #fff7e9;
}

.public-message-comments-container {
    height: 50vh;
    overflow-y: scroll;
}

.comment-time {
    font-size: x-small;
    color: #8f9cb3;
}

.comment-avatar {
    border-radius: 50% !important;
}

.comment-message-time {
    color: #fff7e9;
    font-size: small;
}

.no-comment-container {
    height: 30vh;
    color: white;
    font-size: large;
}

.character-profile-right-container {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.ai-profiles-tab-container {
    width: 100%;
    border: 1px solid #B7C1D3;
}

@media only screen and (max-width: 768px) {
    .character-profile-right-container {
        width: 100%;
    }

    .public-chat-item {
        cursor: pointer;
        border: 4px solid #e5e5e5;
        width: 100%;
    }
}

.public-pagination-messages-container {
    height: 90%;
}

.public-messages-container {
    height: 90%;
    overflow-y: scroll;
}

.ai-character-modal-avatar {
    width: 30px!important;
    border-radius: 50%!important;
}

.character-multi-image-container {
    display: flex ;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.1em;
    padding: 1em;
}


.character-multi-image {
    width: 230px;
    height: 200px;
}

@media screen and (max-width: 1200px) {
    .character-multi-image {
        width: 300px;
        height: 250px;
    }
    .character-multi-image-container {
        gap: 2em;
    }
}

@media screen and (max-width: 1087px) {
    .character-multi-image {
        width: 255px;
        height: 200px;
    }
}

@media screen and (max-width: 960px) {
    .character-multi-image {
        width: 200px;
        height: 200px;
    }
}

@media screen and (max-width: 802px) {
    .character-multi-image {
        width: 300px;
        height: 200px;
    }

    .character-multi-image-container {
        justify-content: center;
    }
}

.character-multi-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ReactModal__Content {
    background-color: #5536A0!important;
}