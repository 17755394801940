.search-container {
 display: flex;
 flex-direction: row;
 margin: 1em;
 width: 100%;
 padding-right: 2%;
}

#category-input {
    width: 30%;
}

.bg-selected {
    background-color: #FEAF1A;
    color: white!important;
}

.scroll-arrow-container{
    z-index: 1;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    background: transparent;
    border: none;
}

.scroll-arrow-container::before{
    z-index: -1;
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 50px;
    background: linear-gradient(164deg, rgba(43,110,209,1) 0%, rgba(6,37,80,1) 100%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
                mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
    pointer-events: none;
}

.top-creator-container{
    overflow-x: scroll;
}

.top-creator-container::-webkit-scrollbar {
    display: none;
}

.top-creator-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}