.main-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
}

.deal-info-merchant-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
    background-color: #F2F2F2;
    padding: 1em;
}

.merchant-image-container {
    width: 22%;
}

.merchant-image-container img {
    width: 100%;
    height: 100%;
}

.deal-info-container {
    display: flex;
    flex-direction: column;
    gap: 0.6em;
}

.tabs-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.review-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.review-avatar-container {
    width: 50px;
}

.review-avatar-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}