/* public Coupon Screen puchase css */

.theme-bg {
    background-color: #FF731D!important;
}

/* coupon page */

@media (max-width:700px){
    .coupon-info-container {
        flex-direction: column;
        border-radius: 1em;
        background-color: #F2F2F2;
        display: flex;
        padding: 2em;
        gap: 4em;
    }


}
@media (min-width:750px){
    .coupon-info-container {
        border-radius: 1em;
        background-color: #F2F2F2;
        display: flex;
        flex-direction: row;
        padding: 2em;
        gap: 4em;
    }
}

.coupon-page-container {
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 2em
}

.coupon-price {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgb(92, 89, 89);
}

.spinner-container {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.store-info-container {
    cursor: pointer;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    text-align: center;
}

.store-img-container {
    width: 50px;
    height: 50px;
}

.store-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.store-card-img-container {
    width: 35px;
    height: 35px;
}

.coupon-img-container {
    width: 40vw;
    height: 35vh;
}

.coupon-right-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.coupon-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.01em;
    text-align: left;
    color: black;
}

.coupon-desc {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: left;
    display: block;
}

.coupon-points {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: right;
    color: #8F9CB3
}

.coupon-points-value {
   color: black
}

.buy-btn {
    background-color: #FF731D!important;
}


/* deal page */

.buy-coupon-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    background: #F2F2F2;
}

.coupon-img-container img {
    
    height: 100%;
    object-fit: cover;
}

.coupon-deal-img-container {
    height: 100%;
}

.coupon-deal-img-container img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.coupon-price-container {
    padding: 1em;
    border: 1px solid #E5E5E5;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.coupon-price-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.coupon-price-row span {
    font-size: 1.1em;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #8F9CB3;
}

.heading {
    font-weight: bold;
    color: black!important;
}

.buy-coupon-main-container {
    /* z-index: 300; */
    display: flex;
    flex-direction: column;
    gap: 2em;
    height: 100%;
    width: 80%;
}

.buy-coupon-btn-spinner {
    margin-left: 1em;
}

/* coupon card */

.coupon-card-container {
    cursor: pointer;
    padding: 1em;
    border: 1px solid #E5E5E5;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.coupon-card-container:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.coupon-card-img-container {
    width: 235px;
    height: 150px;
    position: relative;
}

.coupon-card-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.br-50 {
    border-radius: 50%;
}

.overlay-opacity {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
}

.coupon-merchant-name {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 10%;
    left: 10%;
}

.card-coupon-name {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.01em;
    text-align: left;
    color: white;
}

.card-merchant-name {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgb(200, 198, 198);
}

.coupons-list {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
}