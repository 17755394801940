.earnings-container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 2em;
    background-color: white;
}

.bank-account-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    background: #00000080;
    padding: 1em;
    border-radius: 12px
}

.bank-account-container .top-container {
    display: flex;
    flex-direction: row;
    gap: 13em;
}

.acc-edit-btn {
    cursor: pointer;
    color: #FF731D!important;
}

.acc-name-no {
    color: gray;
}

/* Total earnings table */

.totalEarnings-container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em;
    border: 1px solid #E5E5E5;
    background: #00000080;
    color: white;
    border-radius: 12px
}

.totalEarnings-container-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.grey-text {
    color: #8F9CB3;
}

.withdraw-btn {
    margin-top: 1em;
    background: linear-gradient(293.05deg, #F98B26 0%, #FA2121 100%);

}

.notes-row {
    display: flex;
    flex-direction: column;
}

.earning-headings {
    font-size: large;
}

.referral-earning-card {
    background: #FFFFFF12;
    background-blend-mode: overlay;
    background: linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #FFFFFF 89.19%);
    background: linear-gradient(180deg, #2B2F53 3.16%, #1D1C34 36.05%);
    border: 1px solid;
    border-image-source: linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #FFFFFF 89.19%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07));
    box-shadow: 0px 20px 20px 0px #0000001A;
    box-shadow: 0px 5px 10px 0px #0000000D;
    box-shadow: 0px 2px 4px 0px #0000000D;
    box-shadow: 0px 30px 60px 0px #FFFFFF26 inset;
    border-radius: 9px;
}

.referral-earning-card span {
    color: #B574AB;
}

.referral-avatar {
    border-radius: 50%!important;
}