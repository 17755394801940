.container {
    flex: 1;
    background-color: white;
}

.carouselImageContainer {
    width: calc(80% * var(--deviceWidth));
    height: calc(65% * var(--deviceWidth));
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: hidden;
}

.carouselImage {
    width: 100%;
    height: 100%;
}

.paginationDots {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-left: 8px;
    margin-right: 8px;
}

.slideUploadContainer {
    position: relative;
}

.deleteIcon {
    position: absolute;
    color: white;
    top: 40px;
    z-index: 5;
    right: 55px;
    background-color: black;
    padding: 2px;
    height: 25px;
    width: 25px;
    text-align: center;
    border-radius: 20px;
    /* elevation: 5; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.createStoreContainer {
    border-width: 1px;
    border-color: #e5e7eb;
    padding: 0.75rem;
    margin-bottom: 1.25rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.progressContainer {
    width: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.fieldNameContainer {
    margin-bottom: 2em;
}

.fieldNameLabel {
    font-weight: bold;
    margin-bottom: 1.25rem;
}

.width-100 {
    width: 100%;
}

.error-container {
    color: red;
    margin-top: 1em;
    font-size: x-small;
}

.storeCarouselImage {
    height: 270px;
    width: 270px;
}

.storeCarouselImage img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.image_upload_container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9e9e9;
    height: 270px;
    width: 270px;
    cursor: pointer;
}

  .custom-dropdown .dropdown-menu {
    width: 100%;
    margin: 0 auto;
    color: black;
  }

  button.dropdown-toggle.btn.btn-primary {
    background-color: white;
    color: black;
    border: 1px solid var(--color-400);
  }

  button.dropdown-toggle.btn.btn-primary :hover {
    background-color: var(--color-100);
    color: black;
    border: 1px solid var(--color-400);
  }

  .dropdown {
    width: 100%;
  }
  
  .dropdown-trigger {
    width: 100%;
  }
  
#storeImageInput {
    display: none;
}

.store_image_container {
    height: 270px;
    width: 270px;
}

.store_image_container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.create-character-body {
    position: relative;
    background: linear-gradient(25deg, rgba(170,90,210,1) 0%, rgba(130,47,172,1) 27%, rgb(46, 43, 138) 86%)!important;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
}

.scheduled-message-btn{
    background: linear-gradient(137deg, rgba(43,110,209,1) 0%, rgba(6,37,80,1) 100%);
}

.schedule-modal{
    background-color: transparent;
}

.orange-gradient-btn{
    border-radius: 12px;
    background: var(--red-orange-gradient);
}

.violet-gradient-btn{
    border-radius: 12px;
    background: var(--violet-gradient);
}

.light-blue-gradient-btn{
    border-radius: 12px;
    background: linear-gradient(164deg, rgba(176,197,229,1) 0%, rgba(255,255,255,1) 100%);
    color: black;
}

.transparent-orange-gradient-btn{
    border-radius: 12px;
    background: linear-gradient(0deg, rgba(255,115,29,0.4) 0%, rgba(0, 0, 0, 0) 100%)!important;
}

.schedule-messages-container{
    background: radial-gradient(circle, rgba(0,0,0,0.9) 30%, rgba(41, 42, 42, 0.6) 100%);
    border-radius: 12px;
}

.timezone-input {
    width: 50%;
}

.card .otp-card-body {
    background: radial-gradient(circle, rgba(14, 14, 14, 0.9) 60%, rgba(26, 26, 26, 0.6) 100%);
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.1);
}

.otp-card-modal{
    background: radial-gradient(circle, rgba(16, 11, 32, 0.7) 60%, rgba(32, 31, 31, 0.5) 100%);
}

.otp-card-body{
    background: radial-gradient(circle, rgb(26, 26, 26) 60%, rgb(41, 40, 40) 100%);
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}