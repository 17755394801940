.pricing-main-container {
    margin: 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3em;
}

.pricing-main-container>span {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #000000;
    max-width: 558px;
}

.packages-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 5em;
}

.packages-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 1em;
    justify-content: center;
}

.package-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #E2E2E2;
    border-radius: 16px 0px 0px 16px;
    max-width: 320px;
    overflow: hidden;
}

.no-br {
    border-radius: 0px;
}

.no-b-l {
    border-left: 0px;
}

.no-b-r {
    border-right: 0px;
}

.vip-br {
    border-radius: 0px 16px 16px 0px;
}

.package-first-point {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1em;
    gap: 1em;
}

.package-heading {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #000000;
}

.package-slots-text {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #000000;
}

.package-pricing {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #000000;
}

.start-btn {
    background: #0075FF;
    padding: 8px 15px;
    border-radius: 100px;
    width: 250px;
}

.package-point-containers {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;
    border-left: 0px;
    border-right: 0px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #ffffff;
}

.empty {
    padding: 30px;
}

.card-grey-bg {
    background: #FAFAFA;
}

.email-modal-body{
    display: flex;
    flex-direction: column;
}

.email-modal-body div{
    display: flex;
    flex-direction: row;
}