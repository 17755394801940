.pricing-main-container {
    margin: 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.pricing-main-container>span {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: var(--color-white);
    max-width: 558px;
}

.packages-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 5em;
}

.packages-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 1em;
    justify-content: center;
}

.package-card {
    z-index: 0;
    border-radius: 12px;
    padding: 10px 1rem;
    display: block;
    position: relative;
    background: rgba(47, 47, 47, 0.5);
    border: none;
    box-shadow: 5px 5px 5px 5px rgba(51, 51, 51, 0.1);
    min-width: 300px;
    width: calc(24% - 20px);
}
.package-card:hover::after {
    z-index: -2;
    content: "";
    position: absolute;
    inset: 0;
    padding: 3px;
    border-radius: 12px;
    background: linear-gradient(125deg, rgb(241, 40, 40) 0%, rgb(252, 118, 34) 100%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
                mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
    pointer-events: none;
}

.no-br {
    border-radius: 0px;
}

.no-b-l {
    border-left: 0px;
}

.no-b-r {
    border-right: 0px;
}

.vip-br {
    border-radius: 0px 16px 16px 0px;
}

.package-first-point {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1em;
    gap: 1em;
    justify-content: space-between;
    min-height: 200px;
}

.package-heading {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: var(--color-white);
}

.package-slots-text {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: var(--color-white);
}

.package-pricing {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: var(--color-white);
}

.start-btn {
    background: #0075FF;
    padding: 8px 15px;
    border-radius: 100px;
    width: 250px;
}

.package-points-container{
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
}

.package-point-containers {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;
    border-left: 0px;
    border-right: 0px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 30px;
    letter-spacing: -0.02em;
}

.empty {
    padding: 30px;
}

.card-grey-bg {
    background: #FAFAFA;
}

.email-modal-body{
    display: flex;
    flex-direction: column;
}

.email-modal-body div{
    display: flex;
    flex-direction: row;
}

.gem-count-container {
    background-color: #AA9DC8;
    color: black;
}

@media (max-width: 453px) {
    .gem-count-container {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
        gap: 0.5rem!important;
    }
}