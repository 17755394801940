.orangeLogo-text{

    vertical-align: middle;
    margin-left: 2px;
}
.sidebar-icon {
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
}

.home-icon {
    width: 40px;
    height: 40px;
    margin-right: -0.5em;
    margin-left: -0.3em;
}

.sidebar-icon img{
    width: 100%;
    height: 100%;
}

.sidebar-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.6em;
}

.selected-icon {
    -webkit-filter: invert(54%) sepia(42%) saturate(3121%) hue-rotate(346deg) brightness(101%) contrast(103%);
    filter: invert(54%) sepia(42%) saturate(3121%) hue-rotate(346deg) brightness(101%) contrast(103%);
}

.unselected-icon {
    -webkit-filter: invert(64%) sepia(13%) saturate(485%) hue-rotate(180deg) brightness(95%) contrast(89%);
    filter: invert(64%) sepia(13%) saturate(485%) hue-rotate(180deg) brightness(95%) contrast(89%);
}

.sidebar-back-btn{
    all: unset;
    background-color: #D1D4FF3D;
    padding: 5px 10px 5px 10px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.sidebar-hidden-back-btn{
    position: absolute;
    left: 80px;
}

.sidebar-hr{
    border-color: #3D3A3A;
}

.sidebar-try-btn{
    all: unset;
    background: linear-gradient(30deg, rgba(248, 221, 91, 1) 0%, rgba(216, 20, 48, 1) 50%, rgba(60, 67, 196, 1) 100%);
    border-radius: 50px;
    padding: 1px;
    flex-grow: 1;
    width: 100%;
}

.sidebar-try-btn-content{
    background-color: #272727;
    border-radius: 50px;
    color: white;
    font-size: small;
    text-align: center;
    padding: 15px;
    align-items: center;
    justify-content: center;
}