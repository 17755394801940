#orderbook-container .sc-dkrFOg {
    display: none !important;
}

.overlay {
    position: absolute;
    z-index: 8;
    background: black;
    opacity: 0.3;
    height: 100vh;
    width: 100%;
    display: flex;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    margin: 0;
    padding: 0;

    @media (min-width: 992px) {
        visibility: hidden;
    }
}

.badge-status-success {
    background-color: #15803d;
}

.badge-status-default {
    background-color: #9ca3af;
}
.clickable-card {
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s box-shadow,
        0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
}
.clickable-card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.03);
}

.text-link {
    color: #6c757d;
}

.text-link:hover {
    color: #e39601;
}

small.clickable-list-text {
    color: #8c9db5;
}

h6.clickable-list-text {
    background: linear-gradient(0deg, rgb(190, 200, 239) 0%, rgb(232, 154, 220) 70%, rgb(186, 145, 220) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.clickable-list-text:hover {
    color: #e39601;
}
