.feed-slider {
    height: 100vh;
}

.feed-next-btn{
    all: unset;
    background-color: #FFFFFF1A;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}