.show-modal {
    cursor: pointer;
    color: blue;
}

.notes-row {
    display: flex;
    flex-direction: column;
}

.payoutNotesModalBody-container {
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}