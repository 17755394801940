.wallet-main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2em;
}

.withdraw-form-control {
    padding: 0.5rem;
    border-radius: 5px;
    width: 30%;
    background-color: transparent;
    font-size: 0.75rem;
    border: 1px solid #fff;
    color: #fff;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.withdrawal-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #ddd;
}

.withdrawal-table th,
.withdrawal-table td {
    border: 1px solid #ddd;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 8px;
    text-align: center;
    font-size: 0.7em;
    color: #000;
}

.withdrawal-table th {
    background-color: rgba(255, 255, 255, 0.4);;
    color: #000;
    font-weight: bold;
}

.withdrawal-table td {
    word-wrap: break-word;
}

.transaction-type-container{
    border-radius: 10px;
}
.qr-code-container{
    background-color: #fff;
    width: fit-content;
    border-radius: 5px;
}