.info-selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1em;
    /* gap: 1em; */
    font-size: large;
}

.info-selector div {
    color: white;
    padding: 0em 4em;
    /* border: 1px solid grey; */
    background-color: rgb(192, 191, 191);
}

.info-selector .selected {
    background-color: blue;
}