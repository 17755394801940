.character-card-container {
    display: flex;
    flex-direction: column;
    box-shadow: 20px 8px 80px 0px #0000000A;
    background: linear-gradient(0deg, rgb(30, 34, 64) 0%, rgb(46, 49, 76) 50%, rgb(73, 78, 116) 100%);
    border:none;
    border-radius: 12px;
    gap: 2em;
    cursor: pointer;
}

.character-card-container::before {
    z-index: -1;
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 12px;
    background: linear-gradient(0deg, rgba(62,66,106,1) 0%, rgb(115, 119, 165) 100%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
        -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
                mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
                pointer-events: none;

}

.character-card-cover {
    width: 100%;
    height: 170px;
    position: relative;
}

.character-card-cover img {
    width: 100%;
    height: 100%;
}

.character-card-avatar {
    width: 50px;
    height: 50px;
    margin-left: 10%;
    position: absolute;
    top: 80%;
}

.character-card-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.character-card-info {
    padding: 1em 2em 1em 2em;
}

.character-card-info p {
    margin-top: 1em;
    width: 100%;
}

.character-card-social-container {
    display: flex;
    gap: 1em;
}

.character-card-social {
    width: 30px;
    height: 30px;
}

.character-card-social img {
    width: 100%;
    height: 100%;
}

/*  */

.creators-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
}

.character-card-avatar-lg {
    width: 70px!important;
    height: 70px!important;
    border-radius: 50%!important;
    object-fit: cover;
}

.character-card-avatar-m {
    width: 50px!important;
    height: 50px!important;
    border-radius: 50%!important;
    object-fit: cover;
}

.character-card-desc-p {
    max-width: 300px;
    overflow: hidden;
    background: linear-gradient(0deg, rgb(190, 200, 239) 0%, rgb(232, 154, 220) 70%, rgb(186, 145, 220) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.character-card-creator {
    color: grey;
}

.character-card-details{
    background: linear-gradient(309deg, rgba(214,222,255,1) 0%, rgba(116,126,181,1) 72%, rgba(110,66,145,1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.px-8 {
    padding: 6em;
}

.deal-card {
    cursor: pointer;
    border: 1px solid rgb(232, 230, 230);
    width: fit-content;
}

.deal-image {
    width: 300px;
    height: 180px;
    position: relative;
}

.deal-img-overlay {
    background-color: black;
    opacity: 0.2;
   /*  z-index: 300; */
    width: 100%;
    height: 100%;
    position: absolute;
}

.deal-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.deal-info {
    position: absolute;
    bottom: 10%;
    left: 5%;
    align-items: flex-start;
    /* z-index: 100; */
}

.deal-price {
    background-color: red;
    border-radius: 14px;
    padding: 0.3em 0.8em;
}

.deal-store-img {
    border-radius: 50%!important;
}

.color-white {
    color: white;
}

.conversation-border {
    
    border-radius: 12px;
    padding: 10px 3rem;
    display: inline-block;
    position: relative;
    background: rgba(47, 47, 47, 0.5);
    border: none;
}
.conversation-border::after {
    z-index: -1;
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 12px;
    background: linear-gradient(87.36deg, #FF731D 6.42%, #0DD7E3 84.24%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
                mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
    pointer-events: none;
}