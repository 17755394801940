/* Make the badge float in the top right corner of the button */
.button__badge {
    background-color: #fa3e3e;
    border-radius: 12px;
    color: white;
    padding: 2px 4px;
    font-size: 10px;
    left: 12px;
    position: absolute; /* Position the badge within the relatively positioned button */
    
  }

.fa-bars {
    font-size: 22px;
}

.chat-toggle-btn{
  height: 2em;
  width: 4em;
  font-size: 1rem;
}

@media (max-width:767px) {
  .chat-toggle-btn{
    font-size: 1.3rem
  }

  .chat-toggle-btn label{
    font-size: 1rem;
  }
}
.chat-sent-by-user {
  background: #A8A8A8;
  /* background: linear-gradient(25deg, rgba(255, 119, 119, 1) 0%, rgba(255, 115, 29, 1) 100%); */
  border-radius: 20px 20px 5px 20px;
}

.chat-received-by-user {
  background: #272727;
  /* background: linear-gradient(94deg, rgba(43, 110, 209, 1) 0%, rgba(6, 37, 80, 1) 100%); */
  border-radius: 5px 20px 20px 20px;
}

.chat-header {
  padding-bottom: 2em;
}

@media only screen and (max-width: 767px) {
  .chat-header {
    padding-bottom: 0.2em;
  }
}