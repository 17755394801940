
.close-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.close-icon-container i {
    border-radius: 50%;
    padding: 0.1em 0.2em;
    border: 1px solid black;
    cursor: pointer;
    margin-bottom: 0.5em;
}