.main-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 2em;
}

.api-keys-container {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    padding: 2em;
}

.api-keys-container .top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
}

.api-keys-table {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.api-keys-table .header {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    gap: 24.5%;
    padding-bottom: 0.5em;
    border-bottom: 1px solid grey;
}


.api-keys-table-row {
    display: flex;
    flex-direction: row;
    gap: 18%;
    align-items: center;
    padding-bottom: 0.8em;
    border-bottom: 0.5px solid rgb(181, 181, 181);
}

.api-plan-table-row {
    display: flex;
    flex-direction: row;
    gap:10%;
    align-items: center;
    padding-bottom: 0.8em;
    border-bottom: 0.5px solid rgb(181, 181, 181);
}

.api-key-name {
    width: 4vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.api-key-value {
    width: 20%;
}

.view-stats-btn {
    background-color: white !important;
    color: blue !important;
    border: 1px solid blue;
}

.edit-btn {
    background-color: white !important;
    color: black !important;
    border: 0.5px solid grey;
}


#upgrade-plan-btn {
    color: white;
    background-color: blue;
}

.createKeyContainer{
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.delete-icon{
    margin-right: 2em;
    cursor: pointer;
}

#api-key-name-input{
    border: 1px solid #feb019;
}