.introduction-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin-top: 5em;
}

.orangeLogo {
    height: 40px;
}

.introduction-container h1 {
    max-width: 799px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    color: #000000;
    margin-bottom: 0px;
}

.introduction-container>.introduction-points:first-child {
    margin-top: 3em;
}

.introduction-points {
    padding: 0em 1em;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 2em;
}

.introduction-points-font {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #8F9CB3;
}

.currencies-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5em 6em 9em 5em;
}

.currencies-container .rdt_Table{
    border: 1px solid #DDDEDE;
}

.currencies-container .rdt_Table .rdt_TableRow{
    background-color: #fffafa;
    border-top: 1px solid #DDDEDE;
    border-bottom: 1px solid #dddede;
}

.news-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: "2em";
    justify-content: center;
}

.news-container h2 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 65px;
    text-align: center;
    color: #000000;
}

.news-container span{
    color: #8F9CB3;
}

.news-container p {
    max-width: 600px;
    color: #8F9CB3;
    padding: 1em;
}

.news-container .view-all {
    margin-top: 2em;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #FF4560;
}

.news-container .view-all {
   cursor: pointer; 
}

.news-card-container {
    margin-top: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5em;
    justify-content: center;
}

.news-card-container i{
   font-size: 40px;
}

.news-card {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
}

.news-card > div{
    max-width: 520px;
    max-height: 520px;
    cursor: pointer;
}

.news-card > div img{
   width: 100%;
   height: 100%;
}

.news-card p {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0px;
    color: black;
}

.news-card-container i{
    cursor: pointer;
    color: #8F9CB3;
}

.trade-confidence{
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 7em 2em 0em 2em;
    background: #0F112E;
    color: white;
    align-items: center;
    font-family: 'IBM Plex Sans';
}

.trade-confidence h1 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 65px;
}

.trade-confidence span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #8F9CB3;
}

.trade-confidence .screen-image {
    max-width: 1280px;
    max-height: 720px;
    margin-top: 4em;
}

.trade-confidence .screen-image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.start-trading-container{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 5em 2em 4em 2em;
    align-items: center;
    font-family: 'IBM Plex Sans';
    background-color:rgb(239, 245, 249);
}

.start-trading-container h1 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 65px;
}

.start-trading-container span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #8F9CB3;
}

.start-trading-container button {
    background-color: #FF4560;
    color: white;
    border: 0px;
    padding: 0.6em 1em;
}

.footer{
    padding: 6em 2em 1em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #8F9CB3;
}

.black-font{
    color: #000000;
}

.red-font {
    color: #FF4560;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}

.footer-row-1{
    justify-content: space-evenly;
    padding: 0em 0em 5em 0em;
    border-bottom: 1px solid #e5e7e7;
    gap: 2em;
}

.footer-row-2{
    justify-content: space-evenly;
    padding: 3em 0em 5em 0em;
    align-items: center;
    gap: 1em;
}

.footer-column{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.social-icons{
    display: flex;
    flex-direction: row;
    gap: 1em;
    font-size: large;
}