.view-creator-profile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0em;
    gap: 1em;
    align-items: flex-start;
}

@media only screen and (max-width: 1473px) {
    .profiles-tab-container {
        width: 100%!important;
    }
}

.profiles-tab-container {
    border: 1px solid #B7C1D3;
    width: 70%;
}

.profiles-tab-container-tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 1;
    color: #B7C1D3;
}

.profiles-tab {
    padding: 0.6em;
    cursor: pointer;
    width: 33%;
    text-align: center;
    border-bottom: 1px solid #B7C1D3;
}

.profiles-selected-tab {
    color: var(--theme-color);
    border-bottom: 2px solid var(--theme-color);
}

.add-youtube-button {
    background-color: var(--theme-color) !important;
    width: 98% !important;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin: 20px 5px;
}

.youtube-tab-bar{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0em 6em;
    width: 100%;
    align-self: center;
    
}


.profiles-tab-content {
    height: 70vh;
    padding: 0em 2em;
    justify-content: center;
    overflow-y: scroll;
}

.character-card-container {
    display: flex;
    flex-direction: column;
    box-shadow: 20px 8px 80px 0px #0000000A;
    background-color: #FFFFFF;
    border-radius: 12px;
    width: fit-content;
    gap: 2em;
    cursor: pointer;
}

.character-card-cover {
    width: 100%;
    height: 170px;
    position: relative;
}

.character-card-cover img {
    width: 100%;
    height: 100%;
}

.character-card-avatar {
    width: 50px;
    height: 50px;
    margin-left: 10%;
    position: absolute;
    top: 80%;
}

.character-card-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.character-card-info {
    padding: 1em 2em 1em 2em;
}

.character-card-info p {
    margin-top: 1em;
    width: 100%;
}

.character-card-social-container {
    display: flex;
    gap: 1em;
}

.character-card-social {
    width: 30px;
    height: 30px;
}

.character-card-social img {
    width: 100%;
    height: 100%;
}

.user-created-characters {
    overflow-y: scroll;
}